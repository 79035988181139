.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: flex-start;
  background: transparent; /* or your desired color */
  padding: 10px 0;
}

/* Container for the top row */
.header-top {
  position: relative;
  width: 100%;
  max-width: 900px; /* optional limit */
  height: 40px;     /* fixed height for vertical centering */
  margin-bottom: 10px;
}

/* Center: greeting + name pinned to absolute center */
.top-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Right: "Not you?" or "Sign In" pinned to the right */
.top-right {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

/* Bold + underline user name */
.username {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1.1rem;
}

/* "Not you?" link style */
.not-you-link {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-decoration: underline;
  color: #853a38;
  font-weight: 500;
  font-size: 1rem;
}
.not-you-link:hover {
  color: #6d2f2d;
}

/* Sign In link style */
.signin-link {
  background: none;
  color: #853a38;
  text-decoration: underline;
  font-weight: 500;
  font-size: 1rem;
}
.signin-link:hover {
  color: #6d2f2d;
}

/* Bottom row: logo, centered */
.header-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logo-link {
  text-decoration: none;
}

.header-logo {
  height: 80px;
  width: auto;
}

/* Adjust for mobile if needed */
@media (max-width: 600px) {
  .header-top {
    height: 50px; /* slightly taller for smaller screens if needed */
  }
  .username {
    font-size: 1rem;
  }
  .not-you-link,
  .signin-link {
    font-size: 0.9rem;
  }
  .header-logo {
    height: 60px;
  }
}

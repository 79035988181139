/* src/UserInfoForm.css */

/* GLOBAL RESET to remove default margins/padding on mobile */
html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Ensure everything inherits box-sizing */
  *, *::before, *::after {
    box-sizing: inherit;
  }
  
  /* Container for the entire form page, narrower and centered */
  .user-info-container {
    width: 90%;               /* Use 90% for small screens */
    max-width: 400px;         /* Slimmer box on larger screens */
    margin: 40px auto;        /* Centers horizontally */
    background-color: #2b2b2b;
    padding: 20px;
    border-radius: 8px;
    color: white;
    text-align: center;       /* Center all text inside */
  }
  
  /* Back button at the top */
  .back-button {
    margin-bottom: 10px;
    padding: 8px 16px;
    background-color: #853a38;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Page title */
  .user-info-title {
    margin-bottom: 10px;
  }
  
  /* The main form */
  .user-info-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  /* Error message styling */
  .error-text {
    color: red;
  }
  
  /* Each row is its own box */
  .form-row {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border: 1px solid #444;
    padding: 10px;
    border-radius: 4px;
    align-items: center;       /* Center label + input horizontally */
  }
  
  /* For the small label text */
  .form-row label {
    font-weight: bold;
    cursor: pointer;
  }
  
  /* Inputs and selects inside each row */
  .form-row input,
  .form-row select {
    width: 80%;
    max-width: 300px;
    margin: 0 auto;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    text-align: center;       /* Center text inside inputs */
  }
  
  /* The wrapper for the flag icon and the select box */
  .flag-wrapper {
    position: relative;
    width: 80%;
    max-width: 300px;
    margin: 0 auto;
  }
  
  /* Enough space for the flag on the left */
  .flag-wrapper select {
    padding-left: 35px;
  }
  
  /* This is where the flag sprite is displayed */
  .flag {
    display: inline-block; /* Make sure it's displayed */
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
  
  /* Buttons within the form rows */
  .verify-button {
    background-color: #853a38;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* "Browse" button that looks like the others */
  .browse-button {
    background-color: #853a38;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    overflow: hidden;
  }
  
  /* The row for the file input + delete button */
  .file-input-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
  
  /* Delete button styling */
  .delete-button {
    background-color: #800020;
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* A small hint under the passport image row */
  .passport-hint {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #ccc;
  }
  
  /* Centers the Save & Continue button at the bottom */
  .button-row {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  /* Make the Save & Continue button green */
  .save-button {
    background-color: #28a745; /* green */
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
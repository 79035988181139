/* Reset & Global Styles */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 
      'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #853a38;
  overflow-x: hidden;
}

/* Root container */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Center alignment for the entire app */
.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  color: #853a38;
}

/* Utility classes */
.full-height {
  height: 100%;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}

/* Remove focus outline from buttons & inputs */
button:focus, input:focus {
  outline: none;
}

/* Responsive styles */
@media (max-width: 768px) {
  .App {
      padding: 20px;
  }
}

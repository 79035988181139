/* App.css or index.css */

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  color: #853a38;

  /* Fallback BG color if image fails */
  background-color: #853a38;

  /* Optional site-wide background */
  background-image: url('./images/BackPassBackgroundGuy.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.main-content {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.footer {
  text-align: center;
  padding: 10px;
  background-color: transparent;
  border-top: none;
  width: 100%;
  color: white;
  position: relative;
}

@media (max-width: 768px) {
  .main-content {
    padding: 10px;
  }
}
